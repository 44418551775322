// src/App.js
import React from "react";
import CardScanner from "./CardScanner";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>Business Card Scanner</h1>
                <CardScanner />
            </header>
        </div>
    );
}

export default App;

// src/CardScanner.js
import React, { useState } from 'react';
import { db, storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import axios from 'axios';

const CardScanner = () => {
  const [imageUrl, setImageUrl] = useState('');
  const [parsedData, setParsedData] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleImageCapture = async (e) => {
    console.log('handleImageCapture triggered'); // Debug log
    const file = e.target.files[0];
    if (file) {
      setUploading(true);
      const timestamp = new Date().getTime();
      const fileName = `${timestamp}_${file.name}`;
      const storageRef = ref(storage, `images/${fileName}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      setImageUrl(url);
      setUploading(false);
      console.log('Image uploaded and URL obtained:', url); // Debug log
    }
  };

  const handleTextExtraction = () => {
    console.log('handleTextExtraction triggered'); // Debug log
    if (imageUrl) {
      setLoading(true);
      parseTextWithGPT(imageUrl);
    } else {
      console.log('Image URL is not available.'); // Debug log
    }
  };

  const parseTextWithGPT = async (imageUrl) => {
    try {
      console.log('Sending image URL to server:', imageUrl); // Debug log
      const response = await axios.post('http://localhost:3001/parse', { imageUrl });
      const parsedData = response.data.parsedData;
      console.log('Parsed data received:', parsedData); // Debug log
      const jsonData = convertToJSON(parsedData);
      setParsedData(jsonData);
      saveDataToFirebase(jsonData);
    } catch (error) {
      console.error('Error parsing text with GPT:', error);
      setLoading(false);
    }
  };

  const convertToJSON = (data) => {
    const lines = data.split('\n');
    const dataDict = {};
    lines.forEach(line => {
      const [key, value] = line.split(': ', 2);
      if (key && value) {
        dataDict[key.trim()] = value.trim();
      }
    });
    return dataDict;
  };

  const saveDataToFirebase = async (data) => {
    try {
      await addDoc(collection(db, 'businessCards'), {
        ...data,
        timestamp: serverTimestamp(),
      });
      console.log('Document successfully written!');
      setLoading(false);
      // 跳转到指定的URL
      window.location.href = 'https://576e6ecd.anivanceaichatbot.pages.dev/';
    } catch (error) {
      console.error('Error writing document:', error);
      setLoading(false);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleImageCapture}
        disabled={uploading || loading}
      />
      {uploading && <div className="uploading-overlay">Uploading...</div>}
      <button onClick={handleTextExtraction} disabled={loading || uploading || !imageUrl} style={{ marginTop: '10px' }}>
        {loading ? 'Processing...' : 'Extract Text'}
      </button>
      <div>
        {parsedData && (
          <div>
            <h3>Parsed Data:</h3>
            <pre>{JSON.stringify(parsedData, null, 2)}</pre>
          </div>
        )}
      </div>
      <style jsx>{`
        .uploading-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          z-index: 1000;
        }
      `}</style>
    </div>
  );
};

export default CardScanner;

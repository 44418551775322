// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAexoyhCNVOxy6ko6qwlSJgkw7Z-ZNB6vo",
    authDomain: "businesscard-chatbot.firebaseapp.com",
    projectId: "businesscard-chatbot",
    storageBucket: "businesscard-chatbot.appspot.com",
    messagingSenderId: "305341924458",
    appId: "1:305341924458:web:01c0fa0c5e6070d5a0f613"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };

